import React from 'react'
import Container from 'react-bulma-components/lib/components/container';
import Section from 'react-bulma-components/lib/components/section';
import SEO from '../components/seo'

const NotFoundPage = () => (
	<>
		<SEO title='404: Not found' />
		<Section>
			<Container breakpoint="fullhd">
				<h1>Not found</h1>
				<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
			</Container>
		</Section>
	</>
)

export default NotFoundPage
